define("ember-cp-validations/-private/ember-validator", ["exports", "ember-cp-validations/validators/base", "ember-validators"], function (_exports, _base, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _base.default.extend({
    validate: function validate() {
      var result = _emberValidators.validate.apply(void 0, [this._evType].concat(Array.prototype.slice.call(arguments)));

      if (result && _typeof(result) === 'object') {
        return result.message ? result.message : this.createErrorMessage(result.type, result.value, result.context);
      }

      return result;
    }
  });

  _exports.default = _default;
});