define("ember-cp-validations/-private/ember-internals", ["exports", "@ember/-internals/metal/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDependentKeys = getDependentKeys;
  _exports.isDescriptor = isDescriptor;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function getDependentKeys(descriptorOrDecorator) {
    if (_index.default && _index.default.descriptorForDecorator) {
      var descriptor = _index.default.descriptorForDecorator(descriptorOrDecorator);

      return descriptor._dependentKeys || [descriptor.altKey];
    } else {
      return descriptorOrDecorator._dependentKeys;
    }
  }

  function isDescriptor(o) {
    if (_index.default && _index.default.isClassicDecorator) {
      return _index.default.isClassicDecorator(o);
    } else {
      return o && (_typeof(o) === 'object' || typeof o === 'function') && o.isDescriptor;
    }
  }
});