define("ember-cp-validations/validators/inline", ["exports", "ember-cp-validations/validators/base", "@ember/debug"], function (_exports, _base, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *  Accepts a custom `validate` function.
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('inline', {
   *    username: 'offirgolan',
   *    validate(value, options, model, attribute) {
   *      return value === options.username ?
   *             true :
   *             `Username must be ${options.username}`;
   *    }
   *  });
   *  ```
   *
   *  @class Inline
   *  @module Validators
   *  @extends Base
   */
  var _default = _base.default.extend({
    /**
     * Override the validator's `validate` method with the one that was
     * passed in via the options.
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions: function buildOptions() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      (false && !(options && typeof options.validate === 'function') && (0, _debug.assert)("[validator:inline] You must pass in a validate function", options && typeof options.validate === 'function'));
      var opts = Object.assign({}, options);
      this.validate = opts.validate;
      delete opts.validate;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return this._super.apply(this, [opts].concat(args));
    }
  });

  _exports.default = _default;
});