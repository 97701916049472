define("ember-ajax-fetch/-private/utils/is-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isString;

  /**
   * Checks if the passed value is a string
   * @param {*} object
   * @return {boolean}
   * @function isString
   * @private
   */
  function isString(object) {
    return typeof object === 'string';
  }
});