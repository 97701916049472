define("ember-cp-validations/-private/result", ["exports", "@ember/utils", "@ember/array", "@ember/object", "@ember/object/computed", "ember-cp-validations/validations/result-collection", "ember-cp-validations/validations/warning-result-collection", "ember-cp-validations/-private/internal-result-object"], function (_exports, _utils, _array, _object, _computed, _resultCollection, _warningResultCollection, _internalResultObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * __PRIVATE__
   *
   * @module Validations
   * @class Result
   * @private
   */
  var Result = _object.default.extend({
    /**
     * @property model
     * @type {Object}
     */
    model: null,

    /**
     * @property attribute
     * @type {String}
     */
    attribute: '',

    /**
     * @property _promise
     * @async
     * @private
     * @type {Promise}
     */
    _promise: null,

    /**
     * The validator that returned this result
     * @property _validator
     * @private
     * @type {Validator}
     */
    _validator: null,

    /**
     * Determines if the _result object is readOnly.
     *
     * This is needed because ResultCollections and global validation objects control their own
     * state via CPs
     *
     * @property _isReadOnly
     * @private
     * @readOnly
     * @type {Boolean}
     */
    _isReadOnly: (0, _object.computed)('_result', function () {
      var validations = this._result;
      return validations instanceof _resultCollection.default || validations.isValidations;
    }).readOnly(),

    /**
     * @property isWarning
     * @readOnly
     * @type {Boolean}
     */
    isWarning: (0, _computed.readOnly)('_validator.isWarning'),

    /**
     * @property isValid
     * @readOnly
     * @type {Boolean}
     */
    isValid: (0, _computed.readOnly)('_result.isValid'),

    /**
     * @property isInvalid
     * @readOnly
     * @type {Boolean}
     */
    isInvalid: (0, _computed.readOnly)('_result.isInvalid'),

    /**
     * @property isValidating
     * @readOnly
     * @type {Boolean}
     */
    isValidating: (0, _computed.readOnly)('_result.isValidating'),

    /**
     * @property isTruelyValid
     * @readOnly
     * @type {Boolean}
     */
    isTruelyValid: (0, _computed.readOnly)('_result.isTruelyValid'),

    /**
     * @property isTruelyInvalid
     * @readOnly
     * @type {Boolean}
     */
    isTruelyInvalid: (0, _computed.readOnly)('_result.isTruelyInvalid'),

    /**
     * @property isAsync
     * @readOnly
     * @type {Boolean}
     */
    isAsync: (0, _computed.readOnly)('_result.isAsync'),

    /**
     * @property message
     * @readOnly
     * @type {String}
     */
    message: (0, _computed.readOnly)('_result.message'),

    /**
     * @property messages
     * @readOnly
     * @type {Array}
     */
    messages: (0, _computed.readOnly)('_result.messages'),

    /**
     * @property error
     * @readOnly
     * @type {Object}
     */
    error: (0, _computed.readOnly)('_result.error'),

    /**
     * @property errors
     * @readOnly
     * @type {Array}
     */
    errors: (0, _computed.readOnly)('_result.errors'),

    /**
     * @property warningMessage
     * @readOnly
     * @type {String}
     */
    warningMessage: (0, _computed.readOnly)('_result.warningMessage'),

    /**
     * @property warningMessages
     * @readOnly
     * @type {Array}
     */
    warningMessages: (0, _computed.readOnly)('_result.warningMessages'),

    /**
     * @property warning
     * @readOnly
     * @type {Object}
     */
    warning: (0, _computed.readOnly)('_result.warning'),

    /**
     * @property warnings
     * @readOnly
     * @type {Array}
     */
    warnings: (0, _computed.readOnly)('_result.warnings'),

    /**
     * This hold all the logic for the above CPs. We do this so we can easily switch this object out with a different validations object
     * @property _result
     * @private
     * @type {Result}
     */
    _result: (0, _object.computed)('model', 'attribute', '_promise', '_validator', '_resultOverride', function () {
      var model = this.model,
          attribute = this.attribute,
          _promise = this._promise,
          _validator = this._validator;
      return this._resultOverride || _internalResultObject.default.create({
        model: model,
        attribute: attribute,
        _promise: _promise,
        _validator: _validator
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.isAsync && !this._isReadOnly) {
        this._handlePromise();
      }
    },

    /**
     * Update the current validation result object with the given value
     * - If value is undefined or null, set isValid to false
     * - If value is a validations object from a different model/object, set the _result object to the one given (belongs-to)
     * - If value is a collection of result objects, create a Validation Result Collection and set that to the _result object (has-many)
     * - If value is a string, set the message to the given string and set isValid to false
     * - If value is a boolean, set isValid to result
     * - If value is a pojo, update _result object with the information given
     *
     * @method update
     * @private
     * @param value
     */
    update: function update(value) {
      var result = this._result;
      var attribute = this.attribute;
      var isWarning = this.isWarning;
      var Collection = isWarning ? _warningResultCollection.default : _resultCollection.default;

      if ((0, _utils.isNone)(value)) {
        return this.update(false);
      } else if (value.isValidations) {
        this._overrideResult(Collection.create({
          attribute: attribute,
          content: [value]
        }));
      } else if ((0, _array.isArray)(value)) {
        this._overrideResult(Collection.create({
          attribute: attribute,
          content: value
        }));
      } else if (!this._isReadOnly) {
        this._overrideResult(undefined);

        if (typeof value === 'string') {
          var _setProperties;

          (0, _object.setProperties)(this._result, (_setProperties = {}, _defineProperty(_setProperties, isWarning ? 'warningMessage' : 'message', value), _defineProperty(_setProperties, "isValid", isWarning ? true : false), _setProperties));
        } else if (typeof value === 'boolean') {
          (0, _object.set)(result, 'isValid', value);
        } else if (_typeof(value) === 'object') {
          (0, _object.setProperties)(result, value);
        }
      }
    },

    /**
     * Override the internal _result property.
     * @method _overrideResult
     * @param result
     * @private
     */
    _overrideResult: function _overrideResult(result) {
      (0, _object.set)(this, '_resultOverride', result);
    },

    /**
     * Promise handler
     * @method _handlePromise
     * @private
     */
    _handlePromise: function _handlePromise() {
      var _this = this;

      this._promise.then(function (value) {
        return _this.update(value);
      }, function (value) {
        return _this.update(value);
      }).catch(function (reason) {
        // TODO: send into error state
        throw reason;
      });
    }
  });

  var _default = Result;
  _exports.default = _default;
});